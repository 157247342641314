import { base, baseXlsx } from '@/utils/api'

export default {
  getImportedMaterialMedicines(clinicId) {
    return base.get(`/tiss/importedMaterialMedicines/${clinicId}`)
  },
  uploadMaterialMedicinesTable(data, config) {
    return baseXlsx.post('/tiss/importedMaterialMedicines', data, config)
  },
  getImportedMaterialMedicinesTable(clinicId, tableType, tableId, filters={}, page=1) {
    const request = {
      params: { filters }
    }
    return base.get(`/tiss/importedMaterialMedicines/${clinicId}/${tableType}/${tableId}?page=${page}`, request)
  },
  updateMaterialMedicines(clinicId, tableType, data) {
    return base.put(`/tiss/importedMaterialMedicines/${clinicId}/${tableType}`, data)
  },
  updateMaterialMedicinesField(clinicId, tableType, tableId, data) {
    return base.post(`/tiss/importedMaterialMedicines/${clinicId}/${tableType}/${tableId}`, data)
  },
  deleteMaterialMedicinesTable(materialMedicineTableId) {
    return base.delete(`/tiss/importedMaterialMedicines/${materialMedicineTableId}`)
  },
  createMatMedConfig(data) {
    return base.post('tiss/materialsMedicines/configs', data)
  },
  updateMatMedConfig(id, data) {
    return base.put(`tiss/materialsMedicines/configs/${id}`, data)
  },
  deleteMatMedConfig(id) {
    return base.delete(`tiss/materialsMedicines/configs/${id}`)
  },
  listMatMedConfigs(clinicHealthPlanId, page = 1) {
    return base.get(`tiss/materialsMedicines/configs/list/${clinicHealthPlanId}?page=${page}`)
  },
  getMatMedConfigs(configId) {
    return base.get(`tiss/materialsMedicines/configs/${configId}`)
  },
  createTissExpenseException(data) {
    return base.post('tiss/expenses/exceptions', data)
  },
  updateTissExpenseException(id, data) {
    return base.put(`tiss/expenses/exceptions/${id}`, data)
  },
  deleteTissExpenseException(id) {
    return base.delete(`tiss/expenses/exceptions/${id}`)
  },
}