<template>
  <section class="ds-container">
    <section class="ds-header">
      <div class="ds-title">
        <h2>Mat/Med</h2>
        <!-- <span id="help-icon" class="icon-box">
          <HelpCircle class="icon stroke" />
        </span> -->
        <b-tooltip target="help-icon" placement="bottom">
          Materiais e medicamentos.
        </b-tooltip>
      </div>

      <span>
        <b-button
        variant="outline-primary"
        class="mr-2"
        @click="selectTemplateToDownload"
      >
        <Download class="download-icon" />
        Template
      </b-button>
        
        <b-button 
          variant="primary" 
          @click="openImportModal"
        >
          <Upload class="icon mr-1"/>
          Importar tabela
        </b-button>
      </span>
    </section>
    <section class="pt-2 mt-4">
      <Table 
        :clinic="clinic"
        :items="items"
        @loadTable="loadTable"
      />
    </section>

    <ImportTableModal :importedTables="importedTables" :importErrors="importErrors" />
  </section>
</template>

<script>
import api from '@/modules/tiss/matMed/api'
import { getCurrentClinic } from '@/utils/localStorageManager'
import { saveAs } from 'file-saver'

export default {
  components: {
    Download: () => import('@/assets/icons/download.svg'),
    Upload: () => import('@/assets/icons/upload.svg'),
    Table: () => import('@/modules/tiss/matMed/components/matMed/MatMedTable.vue'),
    ImportTableModal: () => import('@/modules/tiss/matMed/components/matMed/ImportTableModal.vue'),
  },
  data() {
    return {
      items: [],
      clinic: getCurrentClinic(),
      importedTables: [],
      importErrors: [],
    }
  },
  async mounted() {
    this.pusher
      .subscribe(`matmed-import-${this.clinic.id}`)
      .bind('uploadCompleted', this.reloadTable, this)
      .bind('uploadError', this.showErrors, this)

    await this.loadTable()
  },
  methods: {
    selectTemplateToDownload(ev) {
      ev.stopPropagation()
      this.$swal({
        id: 'select-template',
        title: 'Download template',
        input: 'select',
        inputLabel: 'Tabela',
        inputOptions: {
          brasindice_table: 'Tabela Brasindice',
          simpro_table: 'Tabela Simpro'
        },
        inputPlaceholder: 'Selecionar',
        showCancelButton: true,
        confirmButtonColor: '#305BF2',
        confirmButtonText: 'Download',
        cancelButtonText: 'Cancelar',
        reverseButtons: true,
        customClass: {
          cancelButton: 'btn-outline-danger',
          confirmButton: 'btn-primary'
        },
        inputValidator: value => {
          return new Promise(resolve => {
            if (value) resolve()
            resolve('Você precisa selecionar uma tabela')
          })
        }
      }).then(async res => {
        const { value, isConfirmed } = res
        if (isConfirmed) {
          this.downloadCSVTemplate(value)
        }
      })
    },
    downloadCSVTemplate(template) {
      const isLoading = this.$loading.show()
      try {
        const templateToDownload = this.getCsv(template)
        const blob = new Blob([`\uFEFF${templateToDownload}`], {
          type: 'text/csv; charset=utf-8'
        })
        const templateName = template === 'brasindice_table' ? 
          'Template_Brasindice' : 'Template_Simpro'
        saveAs(blob, templateName)
      } catch (error) {
        console.log(error)
        this.$toast.error(error.message)
      } finally {
        isLoading.hide()
      }
    },
    getCsv(template) {
      return {
        brasindice_table:
          'PFB;COD_LAB;LAB;COD_ITEM;ITEM;COD_APRES;APRES;PRECO_APRES;FRACIONAMENTO;FLAG_PRECO;PRECO_FRAC;EDICAO;IPI;PIS_COFINS;EAN;CODTISS;CODTUSS;EDICAO_UPDT;',
        simpro_table: 
          'CD_USUARIO;CD_FRACAO;DESCRICAO;VIGENCIA;IDENTIF;PC_EM_FAB;PC_EM_VEN;PC_EM_USU;PC_FR_FAB;PC_FR_VEN;PC_FR_USU;TP_EMBAL;TP_FRACAO;QTDE_EMBAL;QTDE_FRAC;PERC_LUCR;TIP_ALT;FABRICA	CD_SIMPRO;CD_MERCADO;PERC_DESC;IPI_PRODUTO;REGISTRO_ANVISA;VALIDADE_ANVISA;CD_BARRA;LISTA;HOSPITALAR;FRACIONAR;CD_TUSS;CD_CLASSIF;CD_REF_PRO;GENERICO;DIVERSOS;' 
      }
      [template]
    },
    async loadTable() {
      const loading = this.$loading.show()
      try {
        const { data } = await api.getImportedMaterialMedicines(this.clinic.id)

        this.items = data
        this.importedTables = data.map(item => item.table_type)
      } catch (error) {
        this.$toast.error(error.message)
      } finally {
        loading.hide()
      }
    },
    async reloadTable(data) {
      const hasResults = this.items.find(i => i.table_type === data.type)

      if (!hasResults) {
        await this.loadTable()
        this.$bvModal.hide('import-table-modal')
      }
    },
    async openImportModal() {
      this.importErrors = []
      this.$bvModal.show('import-table-modal')
    },
    async showErrors(data) {
      this.importErrors = data
    },
  }
}
</script>

<style lang="scss" src="@/modules/tiss/matMed/styles/tiss.scss" />